<template>
  <div>
    <img v-for="item in items" :key="item" :src="getImage(item)" />
  </div>
</template>

<script lang="ts">
export default {
  name: "Preload",
  methods: {
    getImage: function (image: String) {
      return require("../assets/" + image);
    },
  },
  data() {
    return {
      items: [
        "portfolio/centrum-cocktailkaart.png",
        "portfolio/centrum-koffiekaart.png",
        "portfolio/hhj.jpg",
        "portfolio/ifh.jpg",
        "portfolio/lea.jpg",
        "portfolio/tbw.jpg",
        "portfolio/thh.jpg",
        "portfolio/thh-loser.jpg",
        "portfolio/kerkstraat.jpg",
        "portfolio/koestraat.jpg",
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
div {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  right: 0;
  overflow: hidden;
  height: 0;
  width: 0;
}
</style>