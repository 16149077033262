<template>
  <div class="logo">
    <div class="graphic-1"><img src="@/assets/graphic.svg" /></div>
    <div class="graphic-2"><img src="@/assets/graphic.svg" /></div>
    <Logo />
  </div>

  <nav
    :class="{
      'hide-service-items': hideServiceItems,
      active: menuIsActive,
      'mobile-button-only': hideServiceItems,
    }"
  >
    <ul>
      <li>
        <router-link to="/">Home</router-link>
      </li>

      <li class="service-item small">
        <router-link to="/websites" class="pink override-color"
          >Websites</router-link
        >
      </li>

      <li class="service-item small">
        <router-link to="/design" class="baby-blue override-color"
          >Design</router-link
        >
      </li>

      <li class="service-item small">
        <router-link to="/fotografie" class="yellow override-color"
          >Fotografie</router-link
        >
      </li>

      <li>
        <router-link to="/portfolio">Portfolio</router-link>
      </li>
      <li>
        <router-link to="/contact">Contact</router-link>
      </li>
    </ul>
  </nav>

  <div class="toggle-nav" v-on:click="toggleMenu()">
    <img src="@/assets/menu-bars.svg" /><span>Menu</span>
  </div>

  <footer>
    KVK 75666421 | <a href="mailto:info@lyghtning.nl">info@lyghtning.nl</a>
  </footer>
</template>

<script>
import Logo from "@/components/Logo.vue";

export default {
  name: "Menu",
  props: {
    hideServiceItems: Boolean,
    mobileButtonOnly: Boolean,
  },
  data() {
    return {
      menuIsActive: false,
    };
  },
  components: {
    Logo,
  },
  methods: {
    toggleMenu: function () {
      this.menuIsActive = !this.menuIsActive;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.logo {
  z-index: 1;

  .graphic-2,
  .graphic-1 {
    position: absolute;
    top: 0;
    right: -100px;
    left: 0;
    opacity: 0.3;
    z-index: 1;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .graphic-1 {
    animation: rotateCC 50s ease infinite, glow 2s alternate ease infinite;
  }

  .graphic-2 {
    transform: rotate(65deg);
    top: 70px;
    animation: rotate 50s ease infinite, glow 2s alternate ease infinite;
  }

  @keyframes glow {
    100% {
      opacity: 0.7;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(95deg);
    }
  }

  @keyframes rotateCC {
    100% {
      transform: rotate(-30deg);
    }
  }
}

nav {
  position: relative;
  z-index: 2;
  margin-top: auto;
  margin-bottom: auto;

  &.mobile-button-only {
  }

  &.hide-service-items ul li.service-item {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      &:not(last-child) {
        margin-bottom: 10px;
      }

      &.small {
        padding-left: 26px;
        a {
          font-size: 1.5em;
        }
      }

      a {
        transition: left 0.2s ease-out;
        position: relative;

        &.router-link-active {
          left: 5px;
          position: relative;
          &:not(.override-color) {
            color: var(--white);
          }

          &:before {
            position: absolute;
            left: -20px;
            content: "> ";
          }
        }

        &:not(.router-link-active):hover {
          left: 5px;
        }
      }
    }

    a {
      &:not(.override-color) {
        color: var(--muted-black-text);
      }

      text-decoration: none;
      font-size: 2em;
      font-weight: 700;
    }
  }
}

footer {
  display: none;
}

@media (max-width: 768px) {
  .logo {
    .graphic-2,
    .graphic-1 {
      right: auto;
    }
  }

  nav:not(.active) {
    display: none;
  }

  nav.active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 92%);
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .toggle-nav {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    color: var(--white);
    font-weight: bold;
    background: linear-gradient(var(--logo-pink), var(--logo-orange));
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 10;

    img {
      height: 20px;
      margin-right: 10px;
      color: var(--white);
    }
  }
}

@media (min-width: 769px) {
  .toggle-nav {
    display: none;
  }

  footer {
    display: block;
    border-top: 1px solid var(--muted-black-text);
    color: var(--muted-black-text);
    padding-top: 15px;
    font-size: 0.8em;

    a {
      text-decoration: none;
      color: var(--muted-black-text);

      &:hover {
        color: var(--logo-pink);
      }
    }
  }
}
</style>
