
export default {
  name: "Preload",
  methods: {
    getImage: function (image: String) {
      return require("../assets/" + image);
    },
  },
  data() {
    return {
      items: [
        "portfolio/centrum-cocktailkaart.png",
        "portfolio/centrum-koffiekaart.png",
        "portfolio/hhj.jpg",
        "portfolio/ifh.jpg",
        "portfolio/lea.jpg",
        "portfolio/tbw.jpg",
        "portfolio/thh.jpg",
        "portfolio/thh-loser.jpg",
        "portfolio/kerkstraat.jpg",
        "portfolio/koestraat.jpg",
      ],
    };
  },
};
